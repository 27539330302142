<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input class="filter-item" v-model="listQuery.keyword" placeholder="请输入姓名/手机号码" style="width: 220px;" clearable/>

            <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>

            <el-button class="filter-item" type="success" @click="downloadImportRecharge">充值模板</el-button>

            <el-upload class="filter-item" accept=".xlsx,.xls" action="" :show-file-list="false" :auto-upload="false" :on-change="handleImportRecharge">
                <el-button type="warning">导入充值</el-button>
            </el-upload>
        </div>
        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="用户id" width="80" align="center">
                <template slot-scope="scope">
                    <!-- {{ scope.$index }} -->
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="微信" min-width="110" align="center">
                <template slot-scope="scope">
                    <div style="display: flex;flex-direction:column;align-items:center;text-align:center;">
                        <img :src="scope.row.avatar" class="user-avatar">
                        <span>{{ scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="姓名" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.name }}
                </template>
            </el-table-column>

            <el-table-column label="手机号码" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.phone }}
                </template>
            </el-table-column>

            <el-table-column label="余额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.balance }}
                </template>
            </el-table-column>

            <el-table-column label="推广钱包余额" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.wallet ? scope.row.wallet.balance : "0.00" }}
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleWalletLog(scope.row)">余额明细</el-button>

                    <el-button size="mini" type="primary" @click="handleWalletLog2(scope.row)">推广钱包余额明细</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 导入充值 -->
        <el-dialog title="导入数据预览" :visible.sync="importRechargeVisible" width="60%" :close-on-click-modal="false">
            <div style="height: 500px" class="scrollbar">
                <el-scrollbar style="height: 500px">
                    <el-table :data="importRechargeData" style="width: 1100px">
                        <el-table-column label="序号" min-width="60" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.index || "" }}
                            </template>
                        </el-table-column>

                        <el-table-column label="电话" min-width="60" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.phone || "" }}
                            </template>
                        </el-table-column>

                        <el-table-column label="金额" min-width="60" align="center">
                            <template slot-scope="scope">
                                {{ scope.row.amount || "" }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-scrollbar>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="importRechargeVisible = false">取消</el-button>
                <el-button type="primary" @click="importRechargeSaveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 导入充值 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";
    import downloadExcel from "vue-json-excel";
    import {excel2Json} from "@/utils/excel";

    export default {
        components: { downloadExcel },
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    school_id: '',
                    keyword: '',
                },
                // 导入充值
                importRechargeData: [],
                importRechargeVisible: false,
                btnLoading: false,
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"])
        },
        watch: {
            school_id(newVal, oldVal) {
                this.listQuery.school_id = newVal;
                this.getList();
            },
        },
        methods: {
            getList() {
                this.listQuery.school_id = this.school_id;
                this.listLoading = true;
                request({
                    url: "/api/backend/wallet/userWallet",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            // 余额明细
            handleWalletLog(row) {
                this.$router.push('/wallet/userWalletLog?user_id=' + row.id)
            },
            // 推广钱包余额明细
            handleWalletLog2(row) {
                this.$router.push('/wallet/userWalletLog2?user_id=' + row.id)
            },
            // 充值模板
            downloadImportRecharge() {
                let a = document.createElement("a");
                a.href = "/充值模板.xlsx";
                a.download = "充值模板.xlsx";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                a.remove();
            },
            // 导入充值
            handleImportRecharge(file) {
                this.importRechargeData = [];
                file = file.raw;
                if (!(file.name.split(".")[1] === "xlsx" || file.name.split(".")[1] === "xls")) {
                    this.$message.error("上传文件格式不正确，请检查选择的文件");
                    return;
                }
                excel2Json(file, 1).then((excelData) => {
                    // 处理业务
                    if (excelData.length <= 0) {
                        this.$message.error("数据不能为空");
                        return;
                    }
                    if (excelData.length >= 100) {
                        this.$message.error(`数据批量处理限制100以内,当前数量${excelData.length}`);
                        return;
                    }
                    excelData.forEach((element, index) => {
                        this.importRechargeData.push({
                            index: element["序号"]||"",
                            phone: element["电话"].toString()||"",
                            amount: element["金额"]||"",
                        })
                    });
                    this.importRechargeVisible = true;
                });
            },
            importRechargeSaveData() {
                this.btnLoading = true;
                for (let index = 0; index < this.importRechargeData.length; index++) {
                    const item = this.importRechargeData[index];
                    for (var key in item) {
                        if (!item[key]) {
                            this.$message({
                                type: "error",
                                message: "请先完善数据",
                            });
                            this.btnLoading = false;
                            return;
                        }
                    }
                }
                request({
                    url: "/api/backend/wallet/batchRechargeUserWallet",
                    method: "post",
                    data: {
                        data: this.importRechargeData,
                    },
                    timeout: 10 * 60 * 1000,
                }).then((response) => {
                    this.btnLoading = false;
                    this.importRechargeVisible = false;
                    this.$message({
                        type: "success",
                        message: "操作成功",
                    });
                    this.getList();
                }).catch((err) => {
                    console.log(err)
                }).finally(() => {
                    this.btnLoading = false;
                });
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
